import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';

function Summary({summaryCompState, setSenderCompState, setSummaryCompState, finalData, ref, ...props}) {

    const showAgain = () => { 
        setSenderCompState("active"); 
        setSummaryCompState("ready");
    };

    const history = useHistory();

    function submitReturnHandler() {
        let dataReady = {
            id: "",
            createdAt: "",
            sender_fName: "",
            sender_lName: "",
            sender_address: "",
            sender_address2: "",
            sender_city: "",
            sender_zip: "",
            sender_email: "",
            order: "",
            reason: "",
            products: []
        }
        const createdAt = new Date().toLocaleString() + "";
        const uniqueId = uuid();
        dataReady = {...finalData, id: uniqueId, createdAt: createdAt};

        console.log("imame li: ", dataReady);

        fetch(
            'https://612640e43ab4100017a68e5d.mockapi.io/returns',
            {
                method: 'POST',
                body: JSON.stringify(dataReady),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(()=>{
            console.log("All Received. Thank you!");
            history.replace('/thank-you');
        });
    } 

    return (
        <div className={`doNotPrint a-container ${summaryCompState}`}>
            <p className="a-btn">Summary<span onClick={showAgain}></span></p>
            <div className="a-panel summary">
                <p>Parcel label</p>
                <div className="printThis parcelLabel">
                    <div className="top">
                        <div className="company">
                            Company name - Alter Wall 124, Kleinheubach - DE-63922 Freistaat Bayern
                        </div>
                    </div>
                    <div className="sender">
                        <strong>{finalData.sender_fName} {finalData.sender_lName}</strong><br/>
                        {finalData.sender_address && `${finalData.sender_address}, `}
                        {finalData.sender_address2 && finalData.sender_address2}
                        <br/>
                        {finalData.sender_city},
                        <br/>
                        {finalData.sender_zip}
                    </div>
                    <div className="invoicedata">
                        <h3>Invoice</h3>
						<table>
                            <tbody>
							<tr>
								<td>Invoice number:</td>
								<td>Customer care</td>
							</tr>
							<tr>
								<td>Invoice date:</td>
								<td>089 15 59118</td>
							</tr>
							<tr>
								<td>Order date:</td>
								<td>16.09.2021</td>
							</tr>
                            </tbody>
						</table>
						<table>
                            <tbody>
							<tr>
								<td>Customer number:</td>
								<td>Customer care</td>
							</tr>
							<tr>
								<td>Order number:</td>
								<td>{finalData.order}</td>
							</tr>
                            </tbody>
						</table>
						<table>
                            <tbody>
							<tr>
								<td>Kontakt:</td>
								<td>Customer care</td>
							</tr>
							<tr>
								<td>Tel.:</td>
								<td>089 15 59118</td>
							</tr>
							<tr>
								<td>E-mail:</td>
								<td>email@email.com</td>
							</tr>
							<tr>
								<td>Side:</td>
								<td>1</td>
							</tr>
                            </tbody>
						</table>
					</div>
				</div>
                <div className="buttons">
                    <button type="button" className="btn step" onClick={submitReturnHandler}>Submit return</button>
                    <div className="labelActions">
                        <button type="button" className="withIcon" onClick={()=>{window.print()}}>
                            <svg id="print-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="18.589" viewBox="0 0 20 18.589">
                                <g id="Group_5" data-name="Group 5">
                                    <path id="Path_49" data-name="Path 49" d="M18.444,22.59H16.323V18.732a.667.667,0,0,0-.667-.667H4.343a.667.667,0,0,0-.667.667V22.59H1.556A1.557,1.557,0,0,0,0,24.146v6.707a1.557,1.557,0,0,0,1.556,1.556H3.677v3.579a.667.667,0,0,0,.667.667H15.656a.667.667,0,0,0,.667-.667V32.408h2.121A1.557,1.557,0,0,0,20,30.853V24.146A1.557,1.557,0,0,0,18.444,22.59ZM5.01,19.4h9.98V22.59H5.01ZM14.99,35.32H5.01V29.866h9.98C14.99,30.031,14.99,35.2,14.99,35.32Zm.667-8.816h-1.7a.667.667,0,1,1,0-1.333h1.7a.667.667,0,1,1,0,1.333Z" transform="translate(0 -18.065)"/>
                                </g>
                            </svg>
							Print label
						</button>
					</div>
				</div>
			</div>
        </div>
    );
}

export default Summary;