import { Route, Switch } from 'react-router-dom';
import Header from './components/Header'
import MainForm from './components/MainForm'
import ThankYou from './components/formModules/ThankYou'
import './App.css';

function App() {  

  return (
    <Switch>
      <Route path="/" exact={true}>
      <div className="container">
        <Header/>
        <MainForm/>
      </div>
      </Route>
      <Route path="/thank-you">
        <div className="container">
          <Header/>
          <ThankYou/>
        </div>
      </Route>
    </Switch>
  );
}

export default App;
