import { useState, useEffect } from "react";

let isInitial = true; 

export function useFetch(uri) {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(isInitial) { 
            isInitial = false; 
            return; 
        }
        if(!uri) return;
        fetch(uri)
            .then(data => data.json())
            .then(setData)
            .then(() => setLoading(false))
            .catch(setError);
    },[uri]);

    return {
        loading,
        data,
        error
    };
}

