import { useState } from "react";
import { useFetch } from "../../useFetch";
import Product from "./Product";

function OrderItems({ productCompState, setProductCompState, setReasonCompState, ...props }) {

  const [selected, setSelected] = useState();
  const [disabledButton, setDisabledButton] = useState(true);
  
  const showAgain = () => { 
    setProductCompState("active"); 
    setReasonCompState("ready");
  };

  function selectProducthandler(props) {
    if (props.target.value === "false" && !selected) {
      setSelected([props.target.name]);
      setDisabledButton(false);
    }
    if (props.target.value === "false" && selected) {
      setSelected([...selected, props.target.name]);
      setDisabledButton(false);
    }
    if (props.target.value === "true" && selected) {
      const result = selected.filter(
        (selection) => selection !== props.target.name
      );
      setSelected(result);
      if(result.length===0) {
        setDisabledButton(true);
      }
    }
  }
  
  function productHandler(s) {
    if(s.length!==0) {
      const selectedProductsArray = s;
      props.onProdSelection(selectedProductsArray);
    }
  }

  let fetchThat="";
  fetchThat = `https://612640e43ab4100017a68e5d.mockapi.io/orders/${props.order}`;
  
  const { loading, data, error } = useFetch(
    fetchThat
  );
  if (loading) return <h1>loading...</h1>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if(!data) return (<div className="githubUser">No results found</div>); 

    return (
      <div className={`doNotPrint a-container ${productCompState}`}>
        <p className="a-btn">
          What would you like to return?<span onClick={showAgain}></span>
        </p>
        <div className="a-panel">
          <ul className="form-group products">
            {!data.products ? "No results found" : data.products.map((product, p) => (
              <Product
                key={p}
                prodId={product.product}
                onSelect={selectProducthandler}
              />
            ))}
          </ul>
          {data.products && 
          <button
            type="button"
            name="return"
            className="btn step"
            onClick={() => {
              setProductCompState("done");
              setReasonCompState("active");
              productHandler(selected);
            }} 
            disabled={disabledButton}
          >Continue</button>}
        </div>
      </div>
    );
  }

export default OrderItems;
