import {useState, useRef} from 'react'

const SearchOrderForm = ({orderCompState, setOrderCompState, setProductCompState, setOrderId}) => {

    const showAgain = () => {
        setOrderCompState("active");
        setProductCompState("ready");
    }

    const orderId = useRef();
    const [hasOrderId, setHasOrderId] = useState();

    const validateOrderIdHandler = () => {
        if(!isNaN(orderId.current.value)) {setHasOrderId(true); console.log(hasOrderId);}
        if(orderId.current.value==="") { setHasOrderId(false);}
    }


    return (
        <>
            <div className={`doNotPrint a-container ${orderCompState}`}>
                <p className="a-btn">Choose your order<span onClick={showAgain}></span></p>
                <div className="a-panel">
                    <p>Please enter your order number:</p>
                    <div className="form-group">
                        <input type="text" ref={orderId} onChange={validateOrderIdHandler} placeholder="Type order number" required />
                        <button type="button" className="btn step" 
                            onClick={()=>{
                                setOrderCompState("done");
                                setProductCompState("active");
                                setOrderId(orderId.current.value);
                            }} 
                            disabled={!hasOrderId}>View order</button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SearchOrderForm
