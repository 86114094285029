import { useState, useRef } from 'react';


function ReturnReason({reasonCompState, setReasonCompState, setSenderCompState, ...props}) {

    const showAgain = () => { 
        setReasonCompState("active"); 
        setSenderCompState("ready");
    };

    const [disabledButton, setDisabledButton] = useState(true);

    const reasonRef1 = useRef();
    function checkboxHandler(e) {
        const enteredReason = e.target.value;
        props.onChosenReason(enteredReason);
        setDisabledButton(false);
    }

    return (
        <div className={`doNotPrint a-container ${reasonCompState}`}>
            <p className="a-btn">Reason for the return<span onClick={showAgain}></span></p>
            <div className="a-panel">
                <ul className="form-group reasons">
                    <li>
                        <label>
                            <input type="radio" name="reason" value="Damaged" onChange={checkboxHandler} ref={reasonRef1}/> Damaged
                        </label>
					</li>
                    <li>
                        <label>
                            <input type="radio" name="reason" value="Defective unit" onChange={checkboxHandler} ref={reasonRef1} /> Defective unit
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="reason" value="Different than expected" onChange={checkboxHandler}/> Different than expected
                        </label>
					</li>
                    <li>
                        <label>
                            <input type="radio" name="reason" value="I don't like it anymore" onChange={checkboxHandler}/> I don't like it anymore
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="reason" value="Wrong colour" onChange={checkboxHandler}/> Wrong colour
                        </label>
                    </li>
                </ul>
                <button type="button" name="return" className="btn step" 
                    onClick={()=>{
                        setReasonCompState("done");
                        setSenderCompState("active");
                    }} 
                    disabled={disabledButton}>Continue</button>
                
            </div>
        </div>
    );
}

export default ReturnReason;