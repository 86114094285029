import React, { useState } from "react";
import { useFetch } from '../../useFetch';


function Product({ prodId, ...props }) {
    const imgPath = "assets/";
    const[checked, setChecked] = useState(false);
    
    const toggle = () => {
        setChecked(!checked); 
    };
    const { loading, data, error } = useFetch(
        `https://612640e43ab4100017a68e5d.mockapi.io/products/${prodId}`
    );
    if (loading) return <h1>loading...</h1>;
    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
    if(!data) return (<div className="githubUser">No results found</div>);
    
    
    return (
        <li>
            <label htmlFor={`product-${data.id}`}>
                <div className="photo">
                    <img src={imgPath+data.img} alt="Apple iPad Pro 12.9"/>
                </div>
                <input 
                    type="checkbox" 
                    name={data.name} 
                    id={`product-${data.id}`} 
                    value={checked}
                    checked={checked} 
                    onChange={toggle}
                    onClick={props.onSelect}
                />
                {data.name}
            </label>
        </li>
    );
}

export default Product;