function ThankYou(){
    return (
        <main>
        <div className="accordion">
        <div className="a-container">
            <h2 style={{marginTop: "0"}}>Thank You!</h2>
            <p>Your return request was successfully sent.</p>
        </div></div></main>
    );
}

export default ThankYou;