
import bpsLogo from '../images/bps-logo.png'
import bpsLogo2x from '../images/bps-logo_2x.png'

const Header = () => {
    return (
        <header>
            <img src={bpsLogo} srcSet={bpsLogo2x} alt="BPS Return portal" />
			<h1>Return portal</h1>
        </header>
    )
}

export default Header
