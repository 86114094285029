import { useState, useRef } from 'react';

function SenderDetails({senderCompState, setSenderCompState, setSummaryCompState, returnFormData, ...props}) {

    const showAgain = () => { 
        setSenderCompState("active"); 
        setSummaryCompState("ready");
    };

    const [firstNameValue, setFirstNameValue] = useState("");
    const [lastNameValue, setLastNameValue] = useState("");
    const [addressValue, setAddressalue] = useState("");
    const [cityValue, setCityValue] = useState("");
    const [emailValue, setEmailValue] = useState("");

    function emailIsValid (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const [firstNameErr, setFirstNameErr] = useState({});
    const [lastNameErr, setLastNameErr] = useState({});
    const [addressErr, setAddressErr] = useState({});
    const [cityErr, setCityErr] = useState({});
    const [emailErr, setEmailErr] = useState({});

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const address1Ref = useRef();
    const address2Ref = useRef();
    const cityRef = useRef();
    const zipRef = useRef();
    const emailAddrRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        const isValid = formValidation();
        if(isValid) { 
            setSenderCompState("done");
            setSummaryCompState("active");

            const firstNameInput = firstNameRef.current.value;
            const lastNameInput = lastNameRef.current.value;
            const addressInput1 = address1Ref.current.value;
            const addressInput2 = address2Ref.current.value;
            const cityInput = cityRef.current.value;
            const zipInput = zipRef.current.value;
            const emailInput = emailAddrRef.current.value;

            const senderData = {
                sender_fName: firstNameInput,
                sender_lName: lastNameInput,
                sender_address: addressInput1,
                sender_address2: addressInput2,
                sender_city: cityInput,
                sender_zip: zipInput,
                sender_email: emailInput
            }
            props.onAddSender(senderData);
        };
    }

    const formValidation = () => {
        const firstNameErr = {};
        const lastNameErr = {};
        const addressErr = {};
        const cityErr = {};
        const emailErr = {};
        let isValid = true;

        if(firstNameValue.trim().length < 3) {
            firstNameErr.firstNameShort = "Please enter valid first name";
            isValid = false;
        }
        if(firstNameValue.trim().length > 30) {
            firstNameErr.firstNameShort = "Please enter valid first name";
            isValid = false;
        }
        if(lastNameValue.trim().length < 3) {
            lastNameErr.firstNameShort = "Please enter valid last name";
            isValid = false;
        }
        if(lastNameValue.trim().length > 30) {
            lastNameErr.firstNameShort = "Please enter valid last name";
            isValid = false;
        }
        if(addressValue.trim().length < 1) {
            addressErr.addressErrShort = "Required field";
            isValid = false;
        }
        if(cityValue.trim().length < 1) {
            cityErr.cityErrShort = "Required field";
            isValid = false;
        }
        if(emailIsValid(emailValue)===false) {
            emailErr.emailErrShort = "Please enter valid email address";
            isValid = false;
        }
        setFirstNameErr(firstNameErr);
        setLastNameErr(lastNameErr);
        setAddressErr(addressErr);
        setCityErr(cityErr);
        setEmailErr(emailErr);
        return isValid;
    };

    return (
        <div className={`doNotPrint a-container ${senderCompState}`}>
            <p className="a-btn">Sender details<span onClick={showAgain}></span></p>
            <div className="a-panel">
                <form onSubmit={submitHandler}>
                    <div className="form-group sender">
                        <div>
                            <input type="text" name="firstname" placeholder="First name*" onChange={(e)=>{setFirstNameValue(e.target.value)}} ref={firstNameRef} />
                            {Object.keys(firstNameErr).map((key)=> { 
                                return <div className="errorMessage">{firstNameErr[key]}</div>
                            })}
                        </div>
                        <div>
                            <input type="text" name="lastname" placeholder="Last name*" onChange={(e)=>{setLastNameValue(e.target.value)}} ref={lastNameRef} />
                            {Object.keys(lastNameErr).map((key)=> { return <div className="errorMessage">{lastNameErr[key]}</div>})}
                        </div>
                    </div>
                    <div className="form-group sender">
                        <div>
                            <input type="text" name="address1" placeholder="Address 1*" onChange={(e)=>{setAddressalue(e.target.value)}} ref={address1Ref} />
                            {Object.keys(addressErr).map((key)=> { 
                                return <div className="errorMessage">{addressErr[key]}</div>
                            })}
                        </div>
                        <input type="text" name="address2" placeholder="Address 2" ref={address2Ref} />
                    </div>
                    <div className="form-group sender">
                        <div>
                            <input type="text" name="city" placeholder="City*" onChange={(e)=>{setCityValue(e.target.value)}} ref={cityRef} />
                            {Object.keys(cityErr).map((key)=> { 
                                return <div className="errorMessage">{cityErr[key]}</div>
                            })}
                        </div>
                        <input type="text" name="zipcode" placeholder="Zip code" ref={zipRef} />
                    </div>
                    <div className="form-group sender">
                        <div>
                            <input type="text" name="email" placeholder="Email address*" onChange={(e)=>{setEmailValue(e.target.value)}} ref={emailAddrRef}/>
                            {Object.keys(emailErr).map((key)=> { 
                                return <div className="errorMessage">{emailErr[key]}</div>
                            })}
                        </div>
                    </div>
                    <button name="sender" className="btn step">Continue</button>
                </form>
            </div>
        </div>
    );
}

export default SenderDetails;