import { useState } from 'react';
import SearchOrdersForm from './formModules/SearchOrdersForm';
import OrderItems from './formModules/OrderItems';
import ReturnReason from './formModules/ReturnReason';
import SenderDetails from './formModules/SenderDetails';
import Summary from './formModules/Summary';

const MainForm = (props) => {

    //componentStates:
    const [orderCompState, setOrderCompState] = useState("active");
    const [productCompState, setProductCompState] = useState("ready");
    const [reasonCompState, setReasonCompState] = useState("ready");
    const [senderCompState, setSenderCompState] = useState("ready");
    const [summaryCompState, setSummaryCompState] = useState("ready");

    //otherstates: 
    const [orderId, setOrderId] = useState();
    const [returnFormData, setReturnFormData] = useState({
        id: {},
        createdAt: "",
        sender_fName: "",
        sender_lName: "",
        sender_address: "",
        sender_address2: "",
        sender_city: "",
        sender_zip: "",
        sender_email: "",
        order: "",
        reason: "",
        products: []
    });

    //return form details:
    function addReturnHandler(enteredReason) { 
        setReturnFormData((prevState)=>{
            return { ...prevState, reason:enteredReason, order: orderId};
        });
        /*let tempData = {...returnFormData, reason:enteredReason, order: orderId};
        setReturnFormData(tempData);*/
    }
    function addSenderHandler(senderData) {
        setReturnFormData((prevState)=>{
            return {...prevState, ...senderData};
        });
        /*let senderAddition = {...returnFormData, ...senderData};
        setReturnFormData(senderAddition);*/
        console.log(returnFormData);
    }
    function addProductsHandler(selectedProductsArray) { 
        console.log(selectedProductsArray);
        setReturnFormData((prevState)=>{
            return {...prevState, products:selectedProductsArray};
        });
        /*let tempProds = {...returnFormData, products:selectedProductsArray};
        setReturnFormData(tempProds);*/
    }
    console.log(returnFormData);


    return (
        <main>
            <div className="doNotPrint accordion">
                <SearchOrdersForm 
                    setOrderId={setOrderId} 
                    orderCompState={orderCompState} 
                    setOrderCompState={setOrderCompState} 
                    setProductCompState={setProductCompState}
                />
                <OrderItems 
                    order={orderId} 
                    productCompState={productCompState} 
                    setProductCompState={setProductCompState}
                    setReasonCompState={setReasonCompState}
                    onProdSelection = {addProductsHandler}
                />
                <ReturnReason
                    reasonCompState={reasonCompState} 
                    setReasonCompState={setReasonCompState}
                    setSenderCompState={setSenderCompState}
                    onChosenReason={addReturnHandler}
                />
                <SenderDetails 
                    senderCompState={senderCompState} 
                    setSenderCompState={setSenderCompState} 
                    setSummaryCompState={setSummaryCompState} 
                    onAddSender={addSenderHandler}
                />
                <Summary 
                    order={orderId} 
                    summaryCompState={summaryCompState}
                    setSenderCompState={setSenderCompState}
                    setSummaryCompState={setSummaryCompState}
                    finalData={returnFormData}
                />
            </div>
        </main>
    )
}

export default MainForm;
